import {
    Address,
    AddressType,
    ChangeAddress,
    ContactDetails,
    ContactDetailsChange,
    HousingOccupancyStatus,
    Identification,
    MarketingSettings,
    MyProfileData,
    ChangeStatusMessage,
    RequestCustomerStatus,
    RequestStatus,
    SmeCustomerData,
} from '@cp-fr/common';
import { StorageUploadFileResponse } from '@cp-shared-8/apis';

export const IdentificationRetailWithAllValues: Identification = {
    customerType: 'Retail',
    data: {
        name: 'Oliver',
        surname: 'Prager',
        customerNumber: '12345',
        isEligibleNpaiNotification: true,
    },
};

export const IdentificationPMWithAllValues: Identification = {
    customerType: 'PM',
    data: {
        companyName: 'Company Example',
        customerNumber: '12345',
        isEligibleNpaiNotification: true,
    } as SmeCustomerData,
};

export const IdentificationRetailWithMissingValues: Identification = {
    customerType: 'Retail',
    data: {
        name: 'Oliver',
        surname: 'Prager',
    },
};

export const IdentificationRetailWithNoValues: Identification = {
    customerType: 'Retail',
    data: {},
};

export const IdentificationSMEWithAllValues: Identification = {
    customerType: 'SME',
    data: {
        name: 'Oliver',
        surname: 'Prager',
        customerNumber: '12345',
        isEligibleNpaiNotification: true,
    },
};

export const IdentificationSMEWithMissingValues: Identification = {
    customerType: 'SME',
    data: {
        name: 'Oliver',
        surname: 'Prager',
    },
};

export const IdentificationSMEWithNoValues: Identification = {
    customerType: 'SME',
    data: {},
};

export const ContactDetailsWithAllValues: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '+33 0623123123',
    otherPhone: '+33 0103474706',
};

export const ContactDetailsWithoutOtherPhone: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '+33 0623123123',
};

export const ContactDetailsWithAllValuesWithoutZeroOnTheBeginning: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '+33 123123123',
    otherPhone: '+33 903474706',
};

export const ContactDetailsWithOnlyMobilePhone: ContactDetails = {
    mobilePhone: '+33 0623123123',
    email: 'oliver.prager@abc.io',
};

export const ContactDetailsWithMissingValues: ContactDetails = {
    email: 'oliver.prager@abc.io',
};

export const ContactDetailsChangeWithAllValues: ContactDetailsChange = {
    emailAddress: 'oliver.prager@abc.io',
    mobilePhoneCountryCode: '+33',
    mobilePhone: '0623123123',
    otherPhoneCountryCode: '+33',
    otherPhone: '0103474706',
};

export const AddressChangeWithNoValues: ChangeAddress = {
    building: '',
    door: '',
    street: '',
    houseNumber: '',
    streetAdd: '',
    postalCode: '',
    city: '',
    country: '',
    documents: [],
    addressType: AddressType.POSTAL,
    housingOccupancyStatus: HousingOccupancyStatus.HOSTED_FREE,
    attempts: 0,
};

export const BillingAddressChangeWithNoValues: ChangeAddress = {
    building: '',
    door: '',
    street: '',
    houseNumber: '',
    streetAdd: '',
    postalCode: '',
    city: '',
    country: '',
    documents: [],
    addressType: AddressType.INVOICE,
    housingOccupancyStatus: HousingOccupancyStatus.HOSTED_FREE,
    attempts: 0,
};

export const AddressChangeWithAllValues: ChangeAddress = {
    building: 'Batiment D',
    door: 'A224',
    street: 'rue de la paix',
    houseNumber: '25',
    streetAdd: 'Lieu dit',
    postalCode: '75008',
    city: 'Santa Cruz',
    country: 'Aruba',
    documents: [],
    addressType: AddressType.POSTAL,
    housingOccupancyStatus: HousingOccupancyStatus.HOSTED_FREE,
    attempts: 0,
};

export const BillingAddressChangeWithAllValues: ChangeAddress = {
    building: 'Batiment A',
    door: 'A221',
    street: 'rue de la paix',
    houseNumber: '25',
    streetAdd: 'Lieu dit',
    postalCode: '75108',
    city: 'Paris',
    country: 'France',
    documents: [],
    addressType: AddressType.INVOICE,
    housingOccupancyStatus: HousingOccupancyStatus.HOSTED_FREE,
    attempts: 0,
};

export const AddressWithAllValues: Address = {
    building: 'Batiment D',
    door: 'A224',
    street: 'rue de la paix',
    houseNumber: '25',
    streetAdd: 'Lieu dit',
    postalCode: '75008',
    city: 'Santa Cruz',
    country: 'Aruba',
};

export const BillingAddressWithAllValues: Address = {
    building: 'Batiment A',
    door: 'C113',
    street: 'rue de la paix',
    houseNumber: '24',
    streetAdd: 'Lieu dit',
    postalCode: '75118',
    city: 'Paris',
    country: 'France',
};

export const AddressWithMissingValues: Address = {
    building: 'Batiment D',
    country: 'France',
};

export const BillingAddressWithMissingValues: Address = {
    building: 'Batiment S',
    country: 'France',
};

export const AddressWithNoValues: Address = {};

export const BillingAddressWithNoValues: Address = {};

export const MarketingSettingsWithAllValues: MarketingSettings = {
    email: true,
    mobilePhone: true,
    homePhone: false,
};

export const MarketingSettingsTrue: MarketingSettings = {
    email: true,
    mobilePhone: true,
    homePhone: true,
};

export const MarketingSettingsFalse: MarketingSettings = {
    email: false,
    mobilePhone: false,
    homePhone: false,
};

export const MarketingSettingsWithMissingValues: MarketingSettings = {
    homePhone: true,
};

export const MarketingSettingsWithNoValues: MarketingSettings = {};

export const ChangeStatusMessageWithAllValues: ChangeStatusMessage = {
    addressChange: false,
    foreignAddressChange: false,
    contactInformationChange: true,
    contactPreferenceChange: false,
    nameChange: false,
    genericRequest: false,
};

export const ChangeStatusMessageWithMissingValues: ChangeStatusMessage = {
    addressChange: false,
    foreignAddressChange: false,
    contactInformationChange: true,
    contactPreferenceChange: false,
    nameChange: false,
};

export const ChangeStatusMessageFalseValues: ChangeStatusMessage = {
    addressChange: false,
    foreignAddressChange: false,
    contactInformationChange: false,
    contactPreferenceChange: false,
    nameChange: false,
};

export const ChangeStatusMessageInProgressResponseWithNameChange: ChangeStatusMessage = {
    addressChange: false,
    foreignAddressChange: false,
    contactInformationChange: false,
    contactPreferenceChange: false,
    nameChange: true,
    genericRequest: false,
};

export const ChangeStatusMessageInProgressResponseWithAddressChange: ChangeStatusMessage = {
    addressChange: true,
    foreignAddressChange: false,
    contactInformationChange: false,
    contactPreferenceChange: false,
    nameChange: false,
    genericRequest: false,
};

export const ChangeStatusMessageInProgressResponseWithContactInformationChange: ChangeStatusMessage = {
    addressChange: false,
    foreignAddressChange: false,
    contactInformationChange: true,
    contactPreferenceChange: false,
    nameChange: false,
    genericRequest: false,
};

export const MyProfileRetailWithAllValues: MyProfileData = {
    identification: IdentificationRetailWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    address: AddressWithAllValues,
    marketingSettings: MarketingSettingsWithAllValues,
    notification: ChangeStatusMessageWithAllValues,
};

export const MyProfileRetailWithMissingValues: MyProfileData = {
    identification: IdentificationRetailWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    address: AddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileRetailWithMissingIdentification: MyProfileData = {
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    address: AddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileRetailWithMissingContact: MyProfileData = {
    identification: IdentificationRetailWithMissingValues,
    address: AddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    contactDetails: ContactDetailsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileRetailWithMissingAddress: MyProfileData = {
    identification: IdentificationRetailWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileRetailWithNoData: MyProfileData = {
    identification: IdentificationRetailWithNoValues,
    contactDetails: ContactDetailsWithMissingValues,
    address: AddressWithNoValues,
    marketingSettings: MarketingSettingsWithNoValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileSMEWithAllValues: MyProfileData = {
    identification: IdentificationSMEWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    address: AddressWithAllValues,
    billingAddress: BillingAddressWithAllValues,
    marketingSettings: MarketingSettingsWithAllValues,
    notification: ChangeStatusMessageWithAllValues,
};

export const MyProfileSMEWithMissingValues: MyProfileData = {
    identification: IdentificationSMEWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    address: AddressWithMissingValues,
    billingAddress: BillingAddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileSMEWithMissingIdentification: MyProfileData = {
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    address: AddressWithMissingValues,
    billingAddress: BillingAddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileSMEWithMissingContact: MyProfileData = {
    identification: IdentificationSMEWithMissingValues,
    address: AddressWithMissingValues,
    billingAddress: BillingAddressWithMissingValues,
    marketingSettings: MarketingSettingsWithMissingValues,
    contactDetails: ContactDetailsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileSMEWithMissingAddress: MyProfileData = {
    identification: IdentificationSMEWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    marketingSettings: MarketingSettingsWithMissingValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const MyProfileSMEWithNoData: MyProfileData = {
    identification: IdentificationSMEWithNoValues,
    contactDetails: ContactDetailsWithMissingValues,
    address: AddressWithNoValues,
    billingAddress: BillingAddressWithNoValues,
    marketingSettings: MarketingSettingsWithNoValues,
    notification: ChangeStatusMessageWithMissingValues,
};

export const RequestCustomerStatusInProgressResponse: RequestCustomerStatus = {
    requestCustomerStatus: RequestStatus.IN_PROGRESS,
};

export const UploadFilesRequestResponse: StorageUploadFileResponse = {
    id: '123456',
    name: 'file_name.png',
    temporalUrl: 'https://example.com/123456',
};
