import { Address } from '@cp-fr/common';
import { InfoIcon, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { DataOverview, Button } from '@vwfs-bronson/bronson-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyObjectOrAllValuesAreUndefined } from 'utils';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { NotFoundError } from '../../not-found-error/NotFoundError';
import { AddressChangeNotification } from '../address-change-notification/AddressChangeNotification';
import { EditStatus } from '../enums';
import { AddressComponent } from './address/AddressComponent';
import { ModificationStatus } from 'components/my-profile/modification-status';

export type ConsultViewProps = {
    address?: Address;
    billingAddress?: Address;
    isPrivateCustomer: boolean;
    startEditing: (addressType: string) => void;
    editPostalAddressStatus: EditStatus;
    editBillingAddressStatus: EditStatus;
    isAddressChangePending: boolean;
    isContactInformationChangePending: boolean;
    isEligibleNpaiNotification?: boolean;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    address,
    billingAddress,
    isPrivateCustomer,
    startEditing,
    editPostalAddressStatus,
    editBillingAddressStatus,
    isAddressChangePending,
    isContactInformationChangePending,
    isEligibleNpaiNotification,
}) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'address.consult-view';

    const [pendingPostal, setPendingPostal] = useState(false);
    const [pendingBilling, setPendingBilling] = useState(false);

    useEffect(() => {
        if (editPostalAddressStatus !== 'SUCCESS' && editPostalAddressStatus !== 'NOT_PERFORMED')
            setPendingPostal(false);
    }, [editPostalAddressStatus]);

    useEffect(() => {
        if (editBillingAddressStatus !== 'SUCCESS' && editBillingAddressStatus !== 'NOT_PERFORMED')
            setPendingBilling(false);
    }, [editBillingAddressStatus]);

    return (
        <>
            <DataOverview
                title={t(`${translationPrefix}.title`)}
                buttonLabel={t('contact-details.consult-view.edit-button')}
                buttonProps={{
                    onClick: () => {
                        setPendingPostal(true);
                        startEditing('postal');
                    },
                    testId: 'editPostalAddressButton',
                    disabled:
                        isAddressChangePending ||
                        isContactInformationChangePending ||
                        editPostalAddressStatus === 'SUCCESS',
                }}
            >
                {isEligibleNpaiNotification && (
                    <Notification
                        status={NotificationStatus.warning}
                        text={t(`${translationPrefix}.notification`)}
                        testId={'npai-warning'}
                        className={'u-mb'}
                    />
                )}
                {!address ? (
                    <NotFoundError />
                ) : isEmpty(address) ? (
                    <NoConnectionNotification />
                ) : (
                    <>
                        {(isAddressChangePending || editPostalAddressStatus === 'SUCCESS') && <ModificationStatus />}
                        <AddressChangeNotification lastEditStatus={editPostalAddressStatus} />
                        <AddressComponent address={address} />
                    </>
                )}
            </DataOverview>
            {!isPrivateCustomer &&
                billingAddress &&
                // eslint-disable-next-line @typescript-eslint/ban-types
                (emptyObjectOrAllValuesAreUndefined(billingAddress as object) ? (
                    <article className="c-data-overview">
                        {(isAddressChangePending || editBillingAddressStatus === 'SUCCESS') && <ModificationStatus />}
                        <AddressChangeNotification lastEditStatus={editBillingAddressStatus} />
                        <Button
                            icon="semantic-forward"
                            iconReversed
                            small
                            link={true}
                            testId="addBillingAddressButton"
                            disabled={
                                isAddressChangePending ||
                                isContactInformationChangePending ||
                                pendingPostal ||
                                editBillingAddressStatus === 'SUCCESS'
                            }
                            onClick={() => {
                                startEditing('billing');
                                setPendingBilling(true);
                            }}
                        >
                            {t('address.consult-view.add-billing-label')}
                        </Button>
                        <InfoIcon text={t('address.consult-view.add-billing-tooltip')} />
                    </article>
                ) : (
                    <DataOverview
                        title={t(`${translationPrefix}.title-sme`)}
                        buttonLabel={t('contact-details.consult-view.edit-button')}
                        buttonProps={{
                            onClick: () => {
                                startEditing('billing');
                                setPendingBilling(true);
                            },
                            testId: 'editBillingAddressButton',
                            disabled:
                                isAddressChangePending ||
                                isContactInformationChangePending ||
                                pendingBilling ||
                                editBillingAddressStatus === 'SUCCESS',
                        }}
                    >
                        {(isAddressChangePending || editBillingAddressStatus === 'SUCCESS') && <ModificationStatus />}
                        <AddressChangeNotification lastEditStatus={editBillingAddressStatus} />
                        <AddressComponent address={billingAddress} />
                    </DataOverview>
                ))}
        </>
    );
};
