import { RequestFormValidatorErrors } from '@cp-fr/common';
import { TFunction } from 'i18next';

export const RequestFormMessages = (t: TFunction): RequestFormValidatorErrors => {
    const translationPrefix = 'validation-error';
    const requiredFieldError = t(`${translationPrefix}.required-field`);
    const invalidYearError = t(`${translationPrefix}.invalid-year`);
    const categoryRequiredError = t(`${translationPrefix}.category-missing`);
    const emailInvalidError = t(`${translationPrefix}.e-mail-invalid`);
    const phoneNumberError = t(`${translationPrefix}.phonenumber-invalid`);
    const dateError = t(`${translationPrefix}.data-selector-missing`);
    const filesNumberError = t(`${translationPrefix}.document-upload-to-many-files`);
    const shortInputRequiredError = t(`${translationPrefix}.short-input-missing`);
    const shortInputMaxLengthError = t(`${translationPrefix}.short-input-too-long`);
    const postCodeLengthError = t(`${translationPrefix}.postcode-invalid`);

    return {
        contractsSelection: {
            required: requiredFieldError,
        },
        categorySelection: {
            required: categoryRequiredError,
        },
        contractsNumbers: {
            required: requiredFieldError,
        },
        requestDocumentsErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            documentType: {
                required: requiredFieldError,
            },
            invoiceMonth: {
                required: requiredFieldError,
            },
            invoiceYear: {
                required: requiredFieldError,
            },
            desiredYear: {
                required: requiredFieldError,
                invalidYear: invalidYearError,
            },
            desiredTaxStatus: {
                required: requiredFieldError,
            },
            periodStartDate: {
                required: requiredFieldError,
            },
            periodEndDate: {
                required: requiredFieldError,
            },
            exitDate: {
                required: requiredFieldError,
            },
            returnDate: {
                required: requiredFieldError,
            },
            countryOfDestination: {
                required: requiredFieldError,
            },
            reason: {
                required: requiredFieldError,
            },
        },
        requestDeadlineErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            deadline: {
                required: requiredFieldError,
                min: requiredFieldError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
        },
        requestLossErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            natureOfClaim: {
                required: requiredFieldError,
            },
            startDate: {
                required: dateError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
        },
        requestAuthorizationDebitMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            nameSurname: {
                required: requiredFieldError,
            },
            authorizes: {
                required: requiredFieldError,
            },
            amount: {
                required: requiredFieldError,
            },
            balanceReason: {
                required: requiredFieldError,
            },
        },
        requestTenantChangeRequestErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phonenumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            transferDate: {
                required: requiredFieldError,
            },
            reason: {
                required: requiredFieldError,
            },
            transferTo: {
                required: requiredFieldError,
            },
            files: {
                dialogueSheet: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                idCopy: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                taxPackage: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                kbisOrInsee: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                newTenantIban: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                vehicleId: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                idCopyManager: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                taxReturn: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
            },
        },
        requestAuthorizationLeaveCountryMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            exitDate: {
                required: dateError,
            },
            returnDate: {
                required: dateError,
            },
            reason: {
                required: requiredFieldError,
            },
            countryOfDestination: {
                required: requiredFieldError,
            },
        },
        requestOtherErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                required: phoneNumberError,
                mustBePhoneNumber: phoneNumberError,
            },
            subjectOfClaim: {
                required: shortInputRequiredError,
                maxLength: shortInputMaxLengthError,
            },
            message: {
                required: t(`${translationPrefix}.free-text-box-missing`),
                maxLength: t(`${translationPrefix}.free-text-box-too-long`),
            },
        },
        requestRegistrationCertificateDuplicateErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            requestReason: {
                required: requiredFieldError,
            },
            files: {
                id: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                proofOfAddress: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                registrationCertificate: {
                    maxLength: filesNumberError,
                },
                lossDeclaration: {
                    maxLength: filesNumberError,
                },
                theftDeclaration: {
                    maxLength: filesNumberError,
                },
            },
        },
        requestFormDeathErrorMessages: {
            dateOfDeath: {
                required: requiredFieldError,
            },
            nameOfNotary: {
                maxLength: shortInputMaxLengthError,
            },
            addressOfNotary: {},
            phonenumberOfNotary: {
                mustBePhoneNumber: phoneNumberError,
            },
            emailOfNotary: {
                mustBeEmail: emailInvalidError,
            },
            civilityOfBeneficiary: {
                required: requiredFieldError,
            },
            firstNameOfBeneficiary: {
                required: shortInputRequiredError,
                maxLength: shortInputMaxLengthError,
            },
            nameOfBeneficiary: {
                required: shortInputRequiredError,
                maxLength: shortInputMaxLengthError,
            },
            addressOfBeneficiary: {
                required: requiredFieldError,
            },
            phonenumberOfBeneficiary: {
                required: phoneNumberError,
                mustBePhoneNumber: phoneNumberError,
            },
            emailOfBeneficiary: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            files: {
                deathCertificate: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                deedOfInheritance: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
            },
        },
        requestContractTransferMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phonenumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            buyerNames: {
                required: requiredFieldError,
            },
            buyerPhonenumber: {
                required: phoneNumberError,
                mustBePhoneNumber: phoneNumberError,
            },
            buyerEmail: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            requestReason: {
                required: requiredFieldError,
            },
            requestReasonDescription: {
                required: requiredFieldError,
            },
            employmentStatus: {
                required: requiredFieldError,
            },
            files: {
                signedConfirmation: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                buyerIdentity: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                bankDetails: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                payslips: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                taxNotice: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                registrationDoc: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                proofOfAddress: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                buyerProofOfAddress: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                accomodationCertificate: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
                indentityDoc: {
                    required: requiredFieldError,
                    maxLength: filesNumberError,
                },
            },
        },
        requestVehicleClaimErrorMessages: {
            reparable: {
                required: requiredFieldError,
            },
            nonReparableFields: {
                email: {
                    required: requiredFieldError,
                    mustBeEmail: emailInvalidError,
                },
                phoneNumber: {
                    required: phoneNumberError,
                    mustBePhoneNumber: phoneNumberError,
                },
                natureOfDamage: {
                    required: requiredFieldError,
                },
                dateOfDamage: {
                    required: dateError,
                },
                nameOfInsurer: {
                    required: requiredFieldError,
                },
                circumstancesOfTheDisaster: {
                    required: requiredFieldError,
                },
                causeOfSinistre: {
                    required: requiredFieldError,
                },
                addressOfInsurer: {
                    required: requiredFieldError,
                },
                postcodeOfInsurer: {
                    required: requiredFieldError,
                    minLength: postCodeLengthError,
                },
                cityOfInsurer: {
                    required: requiredFieldError,
                },
                insuranceCustomerNumber: {
                    required: requiredFieldError,
                },
                damageNumber: {
                    required: requiredFieldError,
                },
                phonenumberOfInsurer: {
                    required: phoneNumberError,
                    mustBePhoneNumber: phoneNumberError,
                },
                emailOfInsurer: {
                    required: requiredFieldError,
                    mustBeEmail: emailInvalidError,
                },
                originalRegDocument: {
                    required: requiredFieldError,
                },
                agreeToSend: {
                    required: requiredFieldError,
                },
                registrationFile: {
                    maxLength: filesNumberError,
                    required: requiredFieldError,
                },
                files: {
                    flightReport: {
                        maxLength: filesNumberError,
                        required: requiredFieldError,
                    },
                    invoice: {
                        maxLength: filesNumberError,
                        required: requiredFieldError,
                    },
                    expertReport: {
                        maxLength: filesNumberError,
                        required: requiredFieldError,
                    },
                    paymentDetails: {
                        maxLength: filesNumberError,
                        required: requiredFieldError,
                    },
                    transferProposal: {
                        maxLength: filesNumberError,
                        required: requiredFieldError,
                    },
                },
            },
        },
        requestChangeDurationMileageErrorMessages: {
            email: {
                required: requiredFieldError,
                mustBeEmail: emailInvalidError,
            },
            phoneNumber: {
                mustBePhoneNumber: phoneNumberError,
            },
            totalDuration: {
                required: requiredFieldError,
            },
            desiredMileage: {
                required: requiredFieldError,
            },
        },
    };
};
