import { Address, adressSectionValidationSchema, ChangeAddress } from '@cp-fr/common';
import {
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedInput,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus, EditViewName } from '../../enums';
import { CountryCodeCombobox } from '../../../contact-section/country-code-combobox/CountryCodeCombobox';
import { countryCodeMapping } from 'components/request/form-view/category-selection/category-view/simple-form-components/phone-input/country-code';

export type FormViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddressDetails?: Address) => void;
    setCurrentView: (newEditView: EditViewName) => void;
    addressChangeValues: ChangeAddress;
    setAddressChangeValues: (addressValues: ChangeAddress) => void;
};

export const FormView: React.FC<FormViewProps> = ({
    cancelEditing,
    addressChangeValues,
    setCurrentView,
    setAddressChangeValues,
}) => {
    const { t } = useTranslation('my-profile');
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileAddressTypedIn',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');

    const translationValidationPrefix = 'address.edit-view.validation';

    const getInitialErrors = (values: { [k: string]: string | undefined }) =>
        ['postalCode', 'city', 'country', 'houseNumber']
            .filter((element) => !values[element] || values[element] === '')
            .join(', ');
    const getErrors = (errors: FormikErrors<ChangeAddress>) => Object.keys(errors).join(`, `);

    const handleStepChange = (values: ChangeAddress): void => {
        const formatedValues: ChangeAddress = { ...values, country: countryCodeMapping[values.country][1] };
        setAddressChangeValues(formatedValues);
        setCurrentView(EditViewName.FILE_UPLOAD_VIEW);
    };

    const errorMessages = {
        houseNumber: {
            max: t(`${translationValidationPrefix}.house-number-invalid`),
            required: t(`${translationValidationPrefix}.house-number-invalid`),
        },
        street: {
            max: t(`${translationValidationPrefix}.street-name-invalid`),
            required: t(`${translationValidationPrefix}.street-name-required`),
        },
        building: {
            max: t(`${translationValidationPrefix}.block-invalid`),
        },
        door: {
            max: t(`${translationValidationPrefix}.door-invalid`),
        },
        postalCode: {
            required: t(`${translationValidationPrefix}.zip-code-invalid`),
            max: t(`${translationValidationPrefix}.zip-code-invalid`),
        },
        city: {
            required: t(`${translationValidationPrefix}.city-invalid`),
            max: t(`${translationValidationPrefix}.city-invalid`),
        },
        country: {
            required: t(`${translationValidationPrefix}.country-invalid`),
            max: t(`${translationValidationPrefix}.country-invalid`),
        },
    };

    return (
        <>
            <Formik
                initialValues={addressChangeValues}
                validationSchema={adressSectionValidationSchema(errorMessages)}
                onSubmit={handleStepChange}
            >
                {(formik) => (
                    <Form onSubmit={(e) => preventSubmit(e)} data-testid="edit-form" onChange={() => onTyping()}>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.house-number')}
                                            name="houseNumber"
                                            testId="house-number"
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.street-name')}
                                            name="street"
                                            testId="street-name"
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.building')}
                                            name="building"
                                            testId="building"
                                            type="text"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.street-add')}
                                            name="streetAdd"
                                            testId="street-add"
                                            type="text"
                                            autocomplete="no"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.door')}
                                            name="door"
                                            testId="door"
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/3" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.postal-code')}
                                            name="postalCode"
                                            testId="postal-code"
                                            type="text"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <ValidatedInput
                                            label={t('address.edit-view.city')}
                                            name="city"
                                            testId="city"
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/2" s="1/1">
                                        <CountryCodeCombobox
                                            name="country"
                                            testId="country"
                                            labelText={t('address.edit-view.country')}
                                            fullCountry
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        onClick={() => {
                                            cancelEditing();
                                        }}
                                        testId="back-button"
                                        type="button"
                                    >
                                        {t('contact-details.edit-view.back-button')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            formik.handleSubmit();
                                            const initialErrors = getInitialErrors({
                                                postalCode: formik.values.postalCode,
                                                city: formik.values.city,
                                                country: countryCodeMapping[formik.values.country][1],
                                            });
                                            if (!isEmpty(formik.errors)) {
                                                const errorsList = getErrors(formik.errors);
                                                onValidationError(errorsList);
                                            } else if (!!initialErrors) {
                                                onValidationError(initialErrors);
                                            }
                                        }}
                                        testId="next-button"
                                        type="button"
                                    >
                                        {t('contact-details.edit-view.confirm-button')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
