import {
    CustomerChangeName,
    formatAsFileSize,
    Identification,
    ChangeNameDocument,
    getChangeNameEndpoint,
    FileInfo,
    editViewIdentificationSectionFrontendSchema,
} from '@cp-fr/common';
import {
    FileUpload,
    preventSubmit,
    Spinner,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { maxFileSize } from 'config';
import { CpDataApi } from 'cp-xhr';
import { InitialValues, initialValues } from './initialValues';
import { getSeparatedFilesIds, uploadFilesToMediaStorage } from './helpers';

export enum EditStatus {
    NOT_PERFORMED = 'NOT_PERFORMED',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    ERROR_VIRUS = 'ERROR_VIRUS',
    NO_CHANGE_DETECTED = 'NO_CHANGE_DETECTED',
    WRONG_REASON = 'WRONG_REASON',
    SIREN_IS_MANDATORY_FOR_SME = 'SIREN_IS_MANDATORY_FOR_SME',
    ERROR_NO_EMAIL = 'USER_WITH_NO_EMAIL',
}

export type EditStatusWithData = {
    status: EditStatus;
    identification?: Identification;
    formValues?: CustomerChangeName;
};

export type EditViewProps = {
    identification: Identification;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedIdentification?: Identification) => void;
    editStatus: EditStatus;
};

export const EditView: React.FC<EditViewProps> = ({ identification, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const trackingSection = 'Identification';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);

    const fileUploadTranslationPrefix = 'identification.edit-view.file-upload';
    const validationPrefix = 'identification.edit-view';

    initialValues.firstName = identification.data.name ?? '';
    initialValues.lastName = identification.data.surname ?? '';

    const handleSubmit = async (values: InitialValues): Promise<void> => {
        setIsSubmitting(true);

        const { firstName, lastName, id, proofOfIdentityChange, proofOfAddress } = values;
        const { name, surname, customerNumber, gender, isEligibleNpaiNotification } = identification.data;

        const documentsValues = {
            id,
            proofOfIdentityChange,
            proofOfAddress,
        };

        const mediaStorageFilesIds: Promise<ChangeNameDocument>[] = uploadFilesToMediaStorage(documentsValues);

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            finishEditing(EditStatus.ERROR);
        };

        const doAfterSuccessfullUpload = (documentsIds: FileInfo[]): void => {
            const preparedChangeValues: CustomerChangeName = {
                customerType: identification.customerType,
                firstName: name === firstName ? '' : firstName,
                lastName: surname === lastName ? '' : lastName,
                documents: [...documentsIds],
            };

            CpDataApi.put(getChangeNameEndpoint(), preparedChangeValues)
                .then(() => {
                    const updatedName: Identification = {
                        customerType: identification.customerType,
                        data: {
                            name: firstName,
                            surname: lastName,
                            customerNumber,
                            gender,
                            isEligibleNpaiNotification,
                        },
                    };
                    setIsSubmitting(false);
                    finishEditing(EditStatus.SUCCESS, updatedName);
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    switch (error.response.data.code) {
                        case 'NO_CHANGE_DETECTED':
                            finishEditing(EditStatus.NO_CHANGE_DETECTED);
                            break;
                        case 'MARKET_API_NOT_REACHABLE':
                            finishEditing(EditStatus.ERROR);
                            break;
                        case 'WRONG_REASON':
                            finishEditing(EditStatus.WRONG_REASON);
                            break;
                        case 'VIRUS':
                            finishEditing(EditStatus.ERROR_VIRUS);
                            break;
                        case 'USER_WITH_NO_EMAIL':
                            finishEditing(EditStatus.ERROR_NO_EMAIL);
                            break;
                        default:
                            finishEditing(EditStatus.ERROR);
                            break;
                    }
                });
        };

        Promise.all(mediaStorageFilesIds)
            .then((filesId: ChangeNameDocument[]) => {
                const separatedDocumentsIds: FileInfo[] = getSeparatedFilesIds(filesId);
                doAfterSuccessfullUpload(separatedDocumentsIds);
            })
            .catch(() => {
                doAfterUploadError();
            });
    };

    const getFileUploadsList = (): { name: string; suffix: string }[] => {
        return [
            { name: 'id', suffix: 'id' },
            { name: 'proofOfIdentityChange', suffix: 'proof-of-identity-change' },
        ];
    };

    const errorMessages = {
        firstName: {
            required: t(`identification.edit-view.validation-error.required-input`),
            matches: t(`${validationPrefix}.validation-error.no-numbers`),
        },
        lastName: {
            required: t(`identification.edit-view.validation-error.required-input`),
            matches: t(`${validationPrefix}.validation-error.no-numbers`),
        },
        id: {
            required: t(`${fileUploadTranslationPrefix}.validation.required`),
            max: t(`${fileUploadTranslationPrefix}.validation.wrong-number-of-files`),
        },
        proofOfIdentityChange: {
            required: t(`${fileUploadTranslationPrefix}.validation.required`),
            max: t(`${fileUploadTranslationPrefix}.validation.wrong-number-of-files`),
        },
    };

    return (
        <DataOverview title={t('identification.edit-view.title')}>
            <Paragraph className={'u-mb'}>{t('identification.edit-view.description')}</Paragraph>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={initialValues}
                validationSchema={editViewIdentificationSectionFrontendSchema(errorMessages)}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={(e) => preventSubmit(e)} data-testid="edit-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <ValidatedInput
                                            label={t('identification.edit-view.first-name')}
                                            name="firstName"
                                            testId="name"
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/2" s="1/1">
                                        <ValidatedInput
                                            label={t('identification.edit-view.last-name')}
                                            name="lastName"
                                            testId="surname"
                                            type="text"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                {getFileUploadsList().map(({ name, suffix }) => {
                                    return (
                                        <Layout.Item key={name}>
                                            <FileUpload
                                                name={name}
                                                descriptionText={t(`${fileUploadTranslationPrefix}.${suffix}`)}
                                                buttonText={t(`${fileUploadTranslationPrefix}.button`)}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                                typeError={t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                                sizeAndTypeError={
                                                    <>
                                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                                        <br />
                                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    );
                                })}
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={cancelEditing} testId="cancel-button" type="button">
                                        {t('identification.edit-view.button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        disabled={isSubmitting}
                                        testId="submit-button"
                                        type="button"
                                    >
                                        {t('identification.edit-view.button.confirm')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
