import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    CivilitySelection,
    DateInput,
    EmailInput,
    FileUpload,
    PhonenumberInput,
    TextArea,
    TextInput,
} from '../../simple-form-components';

export const DeathForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.death';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <Layout.Item>
                <p>{translate('description')}</p>
            </Layout.Item>
            <Layout.Item>
                <DateInput label={translate('date-of-death')} name={'death.dateOfDeath'} />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('name-of-notary')} name={'death.nameOfNotary'} />
            </Layout.Item>
            <Layout.Item>
                <TextArea label={translate('address-of-notary')} name={'death.addressOfNotary'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber-of-notary')}
                    name={'death.phonenumberOfNotary'}
                    isMandatory={false}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email-of-notary')} name={'death.emailOfNotary'} isMandatory={false} />
            </Layout.Item>
            <Layout.Item>
                <CivilitySelection label={translate('civility-of-beneficiary')} name={'death.civilityOfBeneficiary'} />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('firstName-of-beneficiary')} name={'death.firstNameOfBeneficiary'} />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('name-of-beneficiary')} name={'death.nameOfBeneficiary'} />
            </Layout.Item>
            <Layout.Item>
                <TextArea label={translate('address-of-beneficiary')} name={'death.addressOfBeneficiary'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber-of-beneficiary')}
                    name={'death.phonenumberOfBeneficiary'}
                    isMandatory
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email-of-beneficiary')} name={'death.emailOfBeneficiary'} />
            </Layout.Item>
            <>
                <Layout.Item>{translate('files.label')} *</Layout.Item>
                <Layout.Item>
                    <FileUpload name={'death.files.deathCertificate'} label={translate('files.death-certificate')} />
                </Layout.Item>
                <Layout.Item>
                    <FileUpload
                        name={'death.files.deedOfInheritance'}
                        label={translate('files.inheritance-certificate')}
                    />
                </Layout.Item>
            </>
        </>
    );
};
