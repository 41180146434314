import * as Yup from 'yup';
import { RequestFormDeathValidatorErrors } from '../../types';
import { RequestFormDeathError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
import { fileValidation } from '../FileValidator';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestFormDeathFormType = {
    dateOfDeath: string;
    nameOfNotary: string | undefined;
    addressOfNotary: string | undefined;
    phonenumberOfNotary: string | undefined;
    emailOfNotary: string | undefined;
    civilityOfBeneficiary: string;
    firstNameOfBeneficiary: string;
    nameOfBeneficiary: string;
    addressOfBeneficiary: string;
    emailOfBeneficiary: string;
    phonenumberOfBeneficiary: string | undefined;
};

export type RequestFormDeathBackendFormType = RequestFormDeathFormType & {
    files: {
        base64: string | undefined;
        filename: string | undefined;
        mimeType: string | undefined;
        size: number | undefined;
    }[];
};

export type RequestFormDeathFrontendFormType = RequestFormDeathFormType & {
    files: {
        proofOfAddress: string;
        registrationCertificate: string;
        lossDeclaration?: string;
        theftDeclaration?: string;
    };
};

export const requestFormDeathCoreSchema = (
    errors: RequestFormDeathValidatorErrors = RequestFormDeathError,
): Yup.SchemaOf<RequestFormDeathBackendFormType> => {
    return Yup.object().shape({
        dateOfDeath: Yup.string().required(errors.dateOfDeath.required),
        nameOfNotary: Yup.string().trim().max(40, errors.nameOfNotary.maxLength).required(errors.dateOfDeath.required),
        addressOfNotary: Yup.string().trim().optional().required(errors.dateOfDeath.required),
        phonenumberOfNotary: phoneNumberNotRequiredValidation(errors.phonenumberOfNotary.mustBePhoneNumber),
        emailOfNotary: Yup.string().trim().email(errors.emailOfNotary.mustBeEmail),
        civilityOfBeneficiary: Yup.string().trim().required(errors.civilityOfBeneficiary.required),
        firstNameOfBeneficiary: Yup.string()
            .trim()
            .max(40, errors.firstNameOfBeneficiary.maxLength)
            .required(errors.firstNameOfBeneficiary.required),
        nameOfBeneficiary: Yup.string()
            .trim()
            .max(40, errors.nameOfBeneficiary.maxLength)
            .required(errors.nameOfBeneficiary.required),
        addressOfBeneficiary: Yup.string().trim().required(errors.addressOfBeneficiary.required),
        phonenumberOfBeneficiary: phoneNumberNotRequiredValidation(errors.phonenumberOfBeneficiary.required).required(
            errors.dateOfDeath.required,
        ),
        emailOfBeneficiary: Yup.string()
            .trim()
            .email(errors.emailOfBeneficiary.mustBeEmail)
            .required(errors.emailOfBeneficiary.required),
        files: Yup.array()
            .of(
                Yup.object().shape({
                    base64: Yup.string(),
                    filename: Yup.string(),
                    mimeType: Yup.string(),
                    size: Yup.number(),
                }),
            )
            .min(1, errors.files.deathCertificate.required),
    });
};

export const requestFormDeathFrontendValidationSchema = (
    errors: RequestFormDeathValidatorErrors = RequestFormDeathError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestFormDeathFrontendFormType | {}> => {
    return requestFormDeathCoreSchema(errors).shape({
        files: Yup.object().shape({
            deathCertificate: fileValidation(
                errors.files.deathCertificate.maxLength,
                errors.files.deathCertificate.required,
            ),
            deedOfInheritance: fileValidation(
                errors.files.deedOfInheritance.maxLength,
                errors.files.deedOfInheritance.required,
            ),
        }),
    });
};

export const requestFormDeathValidationSchema = (
    field: string[],
    errors: RequestFormDeathValidatorErrors = RequestFormDeathError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestFormDeathFrontendFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory) => {
            return requestCategory === 'DEATH';
        },
        then: requestFormDeathFrontendValidationSchema(errors),
    });
};
