import {
    AuthorizationDebitRequest,
    AuthorizationLeaveCountryRequest,
    ContactDetails,
    ContractTransferRequest,
    DeadlineRequest,
    DeathRequest,
    DocumentsRequest,
    getRequestSendingEndpoint,
    Identification,
    LossRequest,
    OtherRequest,
    RegistrationCertificateRequest,
    RequestCategory,
    VehicleClaimRequestForBalloon,
    VehicleClaimRequestForCredit,
    requestFormValidationSchema,
    ProductTypeEN,
    TenantChangeRequest,
    ChangeDurationMileage,
    ConfirmInformationRequest,
    Contract,
    getDocumentSendingEndpoint,
} from '@cp-fr/common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, ContentSection, Fieldset, Layout, Modal, Spinner } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';
import {
    authorizationDebitMapper,
    deadlineMapper,
    deathMapper,
    documentsMapper,
    lossMapper,
    otherMapper,
    registrationCertificateDuplicateMapper,
    requestContractTransferMapper,
    tenantChangeRequestMapper,
    changeDurationMileageMapper,
    vehicleClaimMapper,
} from './category-selection/category-view/category-views';
import { authorizationLeaveCountryMapper } from './category-selection/category-view/category-views/authorization-leave-country';
import { CategoryView } from './category-selection/category-view/CategoryView';
import { CategorySelection } from './category-selection/CategorySelection';
import { ContractSelection } from './contract-selection/ContractSelection';
import { getInitialValues, RequestInitialValues } from './initialValues';
import { RequestFormMessages } from './validationMessages';
import { TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { CustomerHappinessIndex } from '../../customer-happiness-index/CustomerHappinessIndex';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    MISSING_USER_EMAIL_ERROR = 'USER_WITH_NO_EMAIL',
    NONE = 'NONE',
}

type Request =
    | AuthorizationDebitRequest
    | DeadlineRequest
    | VehicleClaimRequestForCredit
    | VehicleClaimRequestForBalloon
    | DeathRequest
    | LossRequest
    | ContractTransferRequest
    | DocumentsRequest
    | RegistrationCertificateRequest
    | AuthorizationLeaveCountryRequest
    | TenantChangeRequest
    | ChangeDurationMileage
    | ConfirmInformationRequest
    | OtherRequest;

export const getRequestMapper = (
    category: RequestCategory,
): ((values: RequestInitialValues) => Request | Promise<Request> | Promise<Request[]>) => {
    const mapper: {
        [key: string]: (values: RequestInitialValues) => Request | Promise<Request> | Promise<Request[]>;
    } = {
        AUTHORIZATION_DEBIT: authorizationDebitMapper,
        DEADLINE: deadlineMapper,
        VEHICLE_CLAIM: vehicleClaimMapper,
        DEATH: deathMapper,
        LOSS: lossMapper,
        REQUEST_CONTRACT_TRANSFER: requestContractTransferMapper,
        AUTHORIZATION_LEAVE_COUNTRY: authorizationLeaveCountryMapper,
        DOCUMENTS: documentsMapper,
        REGISTRATION_CERTIFICATE_DUPLICATE: registrationCertificateDuplicateMapper,
        TENANT: tenantChangeRequestMapper,
        CHANGE_DURATION_MILEAGE: changeDurationMileageMapper,
        OTHER: otherMapper,
    };
    return mapper[category];
};

export const FormView: React.FC<{
    contracts?: Contract[];
    contactDetails?: ContactDetails;
    identification?: Identification;
    isSMECustomer: boolean;
}> = ({ contracts, contactDetails, identification, isSMECustomer }) => {
    const { t } = useTranslation('request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    const [key, setKey] = useState(Math.random());
    const disableSubmit = useRef(false);

    if (!contracts) {
        return null;
    }

    const handleSubmit = async (
        values: RequestInitialValues,
        // eslint-disable-next-line @typescript-eslint/ban-types
        { resetForm }: { resetForm: Function },
    ): Promise<void> => {
        const category = values.categorySelection;
        if (!category) return;
        const requestMapper = getRequestMapper(category);
        const mappingResult = await requestMapper(values);
        const bodyRequests: Request[] = Array.isArray(mappingResult) ? mappingResult : [mappingResult];
        setIsSubmitting(true);
        const promises = bodyRequests.map((body) => {
            if (
                values.documents.documentType !== 'Copie de mon contrat' &&
                values.documents.documentType !== 'Echéancier' &&
                values.documents.documentType !== 'Duplicata de facture de loyer'
            ) {
                return CpDataApi.post(getRequestSendingEndpoint(), body);
            } else {
                return CpDataApi.post(getDocumentSendingEndpoint(), body);
            }
        });

        await Promise.all(promises)
            .then(() => {
                onSuccess(category);
                setIsSubmitting(false);
                resetForm();
                setMessageType(MessageType.SUCCESS);
            })
            .catch((error) => {
                onError(category);
                setIsSubmitting(false);
                const errorCode = error.response.data.code;
                setMessageType(
                    errorCode === MessageType.MISSING_USER_EMAIL_ERROR
                        ? MessageType.MISSING_USER_EMAIL_ERROR
                        : MessageType.ERROR,
                );
            });
    };

    const handleModalClose = (): void => {
        setMessageType(MessageType.NONE);
        setKey(Math.random());
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleSuccessModalClose = (resetForm: Function): void => {
        resetForm({ values: getInitialValues(contactDetails) });
        handleModalClose();
    };

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    const renderErrorMessage = (msgType: string) => {
        switch (msgType) {
            case MessageType.ERROR:
                return 'modal-error.text';
            case MessageType.MISSING_USER_EMAIL_ERROR:
                return 'modal-missing-email-error.text';
            case MessageType.VALIDATION_ERROR:
                return 'modal-validation-error.text';
            default:
                return 'modal-error.text';
        }
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                enableReinitialize
                initialValues={getInitialValues(contactDetails, false, identification)}
                validationSchema={requestFormValidationSchema(RequestFormMessages(t))}
                onSubmit={handleSubmit}
                validateOnBlur={true}
            >
                {({ values, submitForm, resetForm, dirty, isValid }): JSX.Element => (
                    <Form onSubmit={(e): void => e.preventDefault()} data-testid="request-form">
                        <ContentSection className={'request-certificate-form'} key={key}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ContractSelection
                                                contracts={contracts}
                                                currentContracts={values.contractsSelection}
                                                isSMECustomer={isSMECustomer}
                                            />
                                        </Layout.Item>
                                        <Layout.Item>
                                            <CategorySelection
                                                contractsSelection={values.contractsSelection}
                                                resetForm={resetForm}
                                                currentCategorySelection={values.categorySelection}
                                            />
                                        </Layout.Item>
                                        <CategoryView
                                            contractsSelected={values.contractsSelection}
                                            category={values.categorySelection as RequestCategory}
                                            productTypeEn={
                                                values.contractsSelection.length !== 1
                                                    ? ProductTypeEN.OTHER
                                                    : values.contractsSelection[0].productTypeEN
                                            }
                                            identification={identification}
                                            isSMECustomer={isSMECustomer}
                                        />
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            testId={'dashboard-button'}
                                            type="button"
                                            onClick={(): void => {
                                                history.push(dashboardPagePath());
                                            }}
                                        >
                                            {t('translation:editable-section-nav.dashboard')}
                                        </Button>

                                        <Button
                                            testId={'submit-button'}
                                            disabled={disableSubmit.current === true}
                                            type="submit"
                                            onClick={async (): Promise<void> => {
                                                disableSubmit.current = true;
                                                (!dirty || !isValid) && setMessageType(MessageType.VALIDATION_ERROR);
                                                await submitForm();
                                                disableSubmit.current = false;
                                            }}
                                        >
                                            {t('translation:editable-section-nav.send')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </ContentSection>
                        <Modal
                            shown={[
                                MessageType.MISSING_USER_EMAIL_ERROR,
                                MessageType.VALIDATION_ERROR,
                                MessageType.ERROR,
                            ].includes(messageType)}
                            status="error"
                            onClose={handleModalClose}
                            onCancel={handleModalClose}
                            onClickOutside={handleModalClose}
                            buttonCancelText={t('translation:editable-section-nav.back')}
                            testId={'error-modal'}
                        >
                            {t(renderErrorMessage(messageType))}
                        </Modal>
                        <Modal
                            shown={messageType === MessageType.SUCCESS}
                            status="success"
                            title={t('modal-success.headline')}
                            onClose={(): void => handleSuccessModalClose(resetForm)}
                            onConfirm={handleSuccessModalConfirm}
                            onClickOutside={(): void => handleSuccessModalClose(resetForm)}
                            buttonConfirmText={t('translation:editable-section-nav.dashboard')}
                            testId={'success-modal'}
                        >
                            {t('modal-success.text')}
                        </Modal>
                    </Form>
                )}
            </Formik>
            <CustomerHappinessIndex
                firstName={identification?.data?.name}
                lastName={identification?.data?.surname}
                email={contactDetails?.email}
                customType={TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_REQUEST}
            />
        </>
    );
};
