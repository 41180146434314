import { FinancialDetails } from '@cp-fr/common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import { changePaymentDayPath } from 'components/navigation/paths';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { EditView } from './edit-view/EditView';
import { EditStatus } from './enums';
import { NonEditView } from './non-edit-view/NonEditView';

type CollectionDateSectionProps = {
    details?: FinancialDetails;
    isActive?: boolean;
    contractId: string;
    isContactInformationChangePending: boolean;
    isSMECustomer?: boolean;
    isVDLContract?: boolean;
};

export const CollectionDateSection: React.FC<CollectionDateSectionProps> = ({
    details,
    isActive,
    contractId,
    isContactInformationChangePending,
    isSMECustomer = false,
    isVDLContract = false,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [editStatus, setEditStatus] = useState(EditStatus.NOT_PERFORMED);
    const { onAction } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');

    if (!details || !isActive) {
        return null;
    }

    const startEditing = (): void => {
        onAction();
        history.push(changePaymentDayPath(contractId));
        setEditStatus(EditStatus.NOT_PERFORMED);
    };

    const finishEditing = (editStatus: EditStatus): void => {
        setEditStatus(editStatus);
        history.push(path);
    };

    return (
        <>
            <Hr className={'u-mb-none'} />
            <Switch>
                <Route exact path={changePaymentDayPath()}>
                    <EditView details={details} contractId={contractId} finishEditing={finishEditing}></EditView>
                </Route>
                <Route path={path}>
                    <NonEditView
                        editButtonDisabled={
                            isContactInformationChangePending ||
                            isSMECustomer ||
                            isVDLContract ||
                            editStatus === 'SUCCESS'
                        }
                        details={details}
                        isActive={isActive}
                        startEditing={startEditing}
                        editStatus={editStatus}
                    />
                </Route>
            </Switch>
        </>
    );
};
