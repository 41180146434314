import { Contract } from '../apis';

// For Frontend use only since amountUnpaidNotPaid is mapped to the root of the contract
export const hasUnpaidAmmount = (contract: Contract) => {
    return contract?.amountUnpaidNotPaid !== undefined && contract.amountUnpaidNotPaid > 0;
};

export const isVDLContract = (contract: Contract) => {
    return contract?.productType === 'LVDL_PR';
};

export const isLounLlDSocial = (contract: Contract) => {
    return contract?.productType && contract?.isLeasingSocial && ['LLDN_PR', 'LLDO_PR', 'LOUN_PR', 'LOUO_PR'].includes(contract?.productType);
};
