import {
    Address,
    AddressDocument,
    ChangeAddress,
    formatAsFileSize,
    getUpdateAddressEndpoint,
    HousingOccupancyStatus,
    adressSectionFileUploadFrontendSchema,
} from '@cp-fr/common';
import { isCustomerType } from '@cp-fr/common/src/apis/services/types/contracts';
import { FileUpload, preventSubmit, Spinner } from '@cp-shared-8/frontend-ui';
import {
    Fieldset,
    Form,
    Layout,
    Button,
    ButtonContainer,
    RadioButton,
    RadioButtonGroup,
} from '@vwfs-bronson/bronson-react';
import { maxFileSize } from 'config';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapFileToBase64 } from 'utils/file-utils';
import { EditStatus, EditViewName } from '../../enums';
import { fileTypes, fileUpload, InitialValues, initialValues } from './helpers';

export type FileUploadViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddressDetails?: Address) => void;
    setCurrentView: (newEditView: EditViewName) => void;
    addressChangeValues: ChangeAddress;
    hasBallonContracts?: boolean;
    customerType: isCustomerType;
    isBillingAddress: boolean;
};

export const FileUploadView: React.FC<FileUploadViewProps> = ({
    cancelEditing,
    addressChangeValues,
    finishEditing,
    hasBallonContracts,
    customerType,
    isBillingAddress,
}) => {
    const { t } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState<InitialValues>(initialValues);
    const validationTranslation = t('address.file-upload-view.validation.required');

    const radioItems = [
        {
            label: t('address.file-upload-view.radio-button.hosted-free'),
            value: HousingOccupancyStatus.HOSTED_FREE,
        },
        {
            label: t('address.file-upload-view.radio-button.property'),
            value: HousingOccupancyStatus.PROPERTY_OWNERSHIP,
        },
        {
            label: t('address.file-upload-view.radio-button.rent'),
            value: HousingOccupancyStatus.RENT,
        },
    ];

    const [houseOccuppancy, setHouseOccuppancy] = useState(radioItems[0]);

    const handleSubmit = async (values: InitialValues): Promise<void> => {
        setFormValues(values);
        setIsSubmitting(true);
        const resultFiles: Array<AddressDocument> = await Promise.all(
            Object.entries(values)
                .filter((value) => value[1].length)
                .map(async (value: [string, File[]]) => ({
                    file: await mapFileToBase64(value[1]),
                    type: fileTypes[value[0] as fileUpload],
                })),
        );

        const preparedChangeValues: ChangeAddress = {
            houseNumber: addressChangeValues.houseNumber,
            street: addressChangeValues.street,
            streetAdd: addressChangeValues.streetAdd,
            building: addressChangeValues.building,
            door: addressChangeValues.door,
            postalCode: addressChangeValues.postalCode,
            city: addressChangeValues.city,
            country: addressChangeValues.country,
            documents: resultFiles,
            addressType: addressChangeValues.addressType,
            housingOccupancyStatus: houseOccuppancy.value,
            attempts: addressChangeValues.attempts,
        };

        CpDataApi.put(getUpdateAddressEndpoint(), preparedChangeValues)
            .then(() => {
                const updatedAddress: Address = {
                    houseNumber: addressChangeValues.houseNumber,
                    street: addressChangeValues.street,
                    streetAdd: addressChangeValues.streetAdd,
                    building: addressChangeValues.building,
                    door: addressChangeValues.door,
                    postalCode: addressChangeValues.postalCode,
                    city: addressChangeValues.city,
                    country: addressChangeValues.country,
                };
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS, updatedAddress);
            })
            .catch((error) => {
                setIsSubmitting(false);
                switch (error.response.data.code) {
                    case 'CHECK_FAILURE':
                        finishEditing(EditStatus.CHECK_FAILURE);
                        break;
                    case 'MARKET_API_NOT_REACHABLE':
                        finishEditing(EditStatus.ERROR);
                        break;
                    case 'NOT_ALLOWED_DOCUMENT_TYPE':
                    case 'UNDEFINED_POSTAL_ADDRESS':
                    case 'WRONG_ADDRESS_INFORMATION':
                    case 'PROOF_OF_ADDRESS_MANDATORY':
                    case 'CASE_MANAGEMENT_FAILED':
                        finishEditing(EditStatus.ERROR_DOCUMENTS);
                        break;
                    case 'USER_WITH_NO_EMAIL':
                        finishEditing(EditStatus.NO_EMAIL_ERROR);
                        break;
                    default:
                        finishEditing(EditStatus.ERROR);
                        break;
                }
            });
    };

    const errorMessages = {
        proofOfAddress: {
            required: validationTranslation,
        },
        id: {
            required: validationTranslation,
        },
        accomodationCertificate: {
            required: validationTranslation,
        },
        hostId: {
            required: validationTranslation,
        },
        kbis: {
            required: validationTranslation,
        },
        insee: {
            required: validationTranslation,
        },
        atLeastOne: validationTranslation,
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={formValues}
                validationSchema={adressSectionFileUploadFrontendSchema(
                    customerType.isPrivateCustomer ? 'private' : 'SME',
                    isBillingAddress,
                    errorMessages,
                )}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form onSubmit={(e) => preventSubmit(e)} data-testid="edit-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <p>
                                            {t(
                                                `address.file-upload-view.${
                                                    customerType.isPrivateCustomer ? 'description' : 'description-sme'
                                                }`,
                                            )}
                                        </p>
                                        {customerType.isPrivateCustomer && (
                                            <Layout.Item default="1/1" s="1/1">
                                                <p>{t('address.file-upload-view.occuppancy')}</p>
                                                <RadioButtonGroup>
                                                    {radioItems.map(({ value, label }, index) => (
                                                        <RadioButton
                                                            key={index}
                                                            testId={`goal-${index}`}
                                                            name="goal"
                                                            value={value}
                                                            defaultChecked={index === 0}
                                                            onChange={() => {
                                                                setHouseOccuppancy({ value: value, label: label });
                                                            }}
                                                        >
                                                            {label}
                                                        </RadioButton>
                                                    ))}
                                                </RadioButtonGroup>
                                            </Layout.Item>
                                        )}
                                    </Layout.Item>
                                    {customerType.isPrivateCustomer && (
                                        <Layout.Item>
                                            <FileUpload
                                                name={'proofOfAddress'}
                                                descriptionText={t(
                                                    'address.file-upload-view.proof-of-address.headline',
                                                )}
                                                descriptionSupplementaryText={
                                                    <>
                                                        <li>
                                                            {t('address.file-upload-view.proof-of-address.option1')}
                                                        </li>
                                                        <li>
                                                            {t('address.file-upload-view.proof-of-address.option2')}
                                                        </li>
                                                        <li>
                                                            {t('address.file-upload-view.proof-of-address.option3')}
                                                        </li>
                                                        <li>
                                                            {t('address.file-upload-view.proof-of-address.option4')}
                                                        </li>
                                                    </>
                                                }
                                                buttonText={t('address.file-upload-view.button')}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                typeError={t('address.file-upload-view.validation.wrong-type')}
                                                sizeAndTypeError={
                                                    <>
                                                        {t('address.file-upload-view.validation.wrong-size')}
                                                        <br />
                                                        {t('address.file-upload-view.validation.wrong-type')}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    )}
                                    {!customerType.isPrivateCustomer && (
                                        <Layout.Item>
                                            <FileUpload
                                                name={'kbis'}
                                                descriptionText={t('address.file-upload-view.kbis.headline')}
                                                buttonText={t('address.file-upload-view.button')}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                typeError={t('address.file-upload-view.validation.wrong-type')}
                                                sizeAndTypeError={
                                                    <>
                                                        {t('address.file-upload-view.validation.wrong-size')}
                                                        <br />
                                                        {t('address.file-upload-view.validation.wrong-type')}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    )}
                                    {!customerType.isPrivateCustomer && (
                                        <Layout.Item>
                                            <FileUpload
                                                name={'insee'}
                                                descriptionText={t('address.file-upload-view.insee.headline')}
                                                buttonText={t('address.file-upload-view.button')}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                typeError={t('address.file-upload-view.validation.wrong-type')}
                                                sizeAndTypeError={
                                                    <>
                                                        {t('address.file-upload-view.validation.wrong-size')}
                                                        <br />
                                                        {t('address.file-upload-view.validation.wrong-type')}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    )}
                                    {customerType.isPrivateCustomer && hasBallonContracts === true && (
                                        <Layout.Item>
                                            <FileUpload
                                                name={'id'}
                                                descriptionText={t('address.file-upload-view.id.headline')}
                                                buttonText={t('address.file-upload-view.button')}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                typeError={t('address.file-upload-view.validation.wrong-type')}
                                                sizeAndTypeError={
                                                    <>
                                                        {t('address.file-upload-view.validation.wrong-size')}
                                                        <br />
                                                        {t('address.file-upload-view.validation.wrong-type')}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    )}
                                    {customerType.isPrivateCustomer &&
                                        houseOccuppancy.value === HousingOccupancyStatus.HOSTED_FREE && (
                                            <Layout.Item>
                                                <FileUpload
                                                    name={'accomodationCertificate'}
                                                    descriptionText={t(
                                                        'address.file-upload-view.accomodation-certificate.headline',
                                                    )}
                                                    buttonText={t('address.file-upload-view.button')}
                                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                                    fileSizeFormatter={(bytes: number): string =>
                                                        formatAsFileSize(bytes, 2)
                                                    }
                                                    maxFileSize={maxFileSize}
                                                    multiple={false}
                                                    validFileFormats={['pdf']}
                                                    sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                    typeError={t('address.file-upload-view.validation.wrong-type')}
                                                    sizeAndTypeError={
                                                        <>
                                                            {t('address.file-upload-view.validation.wrong-size')}
                                                            <br />
                                                            {t('address.file-upload-view.validation.wrong-type')}
                                                        </>
                                                    }
                                                />
                                            </Layout.Item>
                                        )}
                                    {customerType.isPrivateCustomer &&
                                        houseOccuppancy.value === HousingOccupancyStatus.HOSTED_FREE && (
                                            <Layout.Item>
                                                <FileUpload
                                                    name={'hostId'}
                                                    descriptionText={t('address.file-upload-view.host-id.headline')}
                                                    buttonText={t('address.file-upload-view.button')}
                                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                                    fileSizeFormatter={(bytes: number): string =>
                                                        formatAsFileSize(bytes, 2)
                                                    }
                                                    maxFileSize={maxFileSize}
                                                    multiple={false}
                                                    validFileFormats={['pdf']}
                                                    sizeError={t('address.file-upload-view.validation.wrong-size')}
                                                    typeError={t('address.file-upload-view.validation.wrong-type')}
                                                    sizeAndTypeError={
                                                        <>
                                                            {t('address.file-upload-view.validation.wrong-size')}
                                                            <br />
                                                            {t('address.file-upload-view.validation.wrong-type')}
                                                        </>
                                                    }
                                                />
                                            </Layout.Item>
                                        )}
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={cancelEditing} testId="cancel-button" type="button">
                                        {t('contact-details.edit-view.back-button')}
                                    </Button>
                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                    <Button onClick={formik.handleSubmit as any} testId="submit-button" type="button">
                                        {t('contact-details.edit-view.confirm-button')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
