import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DeadlineMonthsSelection, EmailInput, PhonenumberInput } from '../../simple-form-components';

type DeadlineFormProps = {
    isLeasingSocial?: boolean;
    isBalloonLeasing?: boolean;
};

export const DeadlineForm: React.FC<DeadlineFormProps> = ({ isLeasingSocial, isBalloonLeasing }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.deadline';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const description = isLeasingSocial
        ? translate('description.leasingSocial')
        : isBalloonLeasing
        ? translate('description.LOAnoLeasingSocial')
        : translate('description.noLeasingSocial');

    const maxSelectionsLeasingSocial = 3;
    const maxSelectionsNoLeasingSocial = 2;
    const deadlineOptionsMaxChoices = isLeasingSocial ? maxSelectionsLeasingSocial : maxSelectionsNoLeasingSocial;

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'deadline.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput label={translate('phonenumber')} name={'deadline.phonenumber'} />
            </Layout.Item>
            <Layout.Item>
                <DeadlineMonthsSelection
                    label={t(`${translationPrefix}.deadline`)}
                    name={'deadline.deadline'}
                    maxSelections={deadlineOptionsMaxChoices}
                />
            </Layout.Item>
        </>
    );
};
