import React, { useCallback } from 'react';
import { FrMonth } from '@cp-fr/common';
import { Combobox, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { ComboboxOption } from '../../../../../../../types/bronson-react';

type MonthSelectionProps = {
    label: string;
    name: string;
    maxSelections: number;
    isMandatory?: boolean;
};

type ComboboxItem = {
    optionKey: string;
    optionValue: string;
    content: React.ReactElement | string;
    testId: string;
};

export const DeadlineMonthsSelection: React.FC<MonthSelectionProps> = ({
    label,
    name,
    maxSelections,
    isMandatory = true,
}) => {
    const [field, meta, helpers] = useField(name);
    const onComboboxChange = useCallback((values: ComboboxOption[]) => {
        helpers.setValue(values.map((value) => value.value).slice(0, maxSelections));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const months: FrMonth[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYearMonthsToDisplay =
        currentMonthIndex === 11 ? [] : months.slice(currentMonthIndex + 1, months.length);

    const getOptionValue = (month: FrMonth, yearOffset: number = 0): string => {
        return `${month} ${currentDate.getFullYear() + yearOffset}`;
    };
    const getOptionTestId = (month: FrMonth): string => {
        return `test-option-${getOptionValue(month)}`;
    };
    const selectItems: ComboboxItem[] = currentYearMonthsToDisplay.length
        ? currentYearMonthsToDisplay.map((month: FrMonth) => {
              const option = getOptionValue(month);
              return {
                  optionValue: option,
                  optionKey: option,
                  content: option,
                  testId: getOptionTestId(month),
              };
          })
        : [];

    const nextYearMonthsToDisplay = months.slice(0, months.length - currentYearMonthsToDisplay.length);
    nextYearMonthsToDisplay.forEach((month: FrMonth) => {
        const option = getOptionValue(month, 1);
        selectItems.push({
            optionValue: option,
            optionKey: option,
            content: option,
            testId: getOptionTestId(month),
        });
    });

    const areAllOptionsSelected = field.value?.length >= maxSelections;

    return (
        <FormField
            type="select"
            testId="deadline-months-selection"
            id="deadline-months-selection"
            labelText={isMandatory ? `${label} *` : label}
            errorMessage={
                meta.touched &&
                meta.error && <ErrorMessage testId="deadline-months-error-message">{meta.error}</ErrorMessage>
            }
        >
            <Combobox
                name={name}
                onBlur={() => helpers.setTouched(true, true)}
                testId={name}
                multiple={true}
                searchable={false}
                onChange={onComboboxChange}
            >
                {selectItems.map((item) => (
                    <Combobox.Item
                        key={item.optionKey}
                        optionKey={item.optionKey}
                        optionValue={item.optionValue}
                        disabled={areAllOptionsSelected && !field.value.includes(item.optionValue)}
                        testId={item.testId}
                    >
                        {item.content}
                    </Combobox.Item>
                ))}
            </Combobox>
        </FormField>
    );
};
