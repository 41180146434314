import {
    ContactDetails,
    Contract,
    Identification,
    ProductTypeEN,
    RegistrationCertificateDuplicateReason,
    RequestCategory,
} from '@cp-fr/common';

export type ChangeMailForm = {
    email: string;
    phonenumber?: string;
    postalAddress: string;
    postcode: string;
    city: string;
    employmentStatus: string;
    files: {
        proofOfAddress?: File[];
        registrationCertificate?: File[];
        accommodationCertificate?: File[];
        identityDocument?: File[];
    };
};

export type AuthorizationDebitForm = {
    email: string;
    phonenumber?: string;
    nameSurname: string;
    authorizes?: string;
    amount: string;
    balanceReason: string;
    otherBalanceReason?: string;
    expirationMonth: string;
};

export type PartialRefundForm = {
    email: string;
    phonenumber?: string;
    goal: string;
    settlementAmount: string;
    partialSettlementReason: string;
    otherPartialSettlementReason?: string;
};

export type DeadlineForm = {
    email: string;
    phonenumber?: string;
    deadline: string[];
};

export type VehicleClaimForm = {
    reparable: string;
    nonReparableFields: {
        email: string;
        productTypeEN: ProductTypeEN | undefined;
        phonenumber: string;
        natureOfDamage: string;
        dateOfDamage: string;
        nameOfInsurer: string;
        circumstancesOfTheDisaster: string;
        causeOfSinistre: string[];
        addressOfInsurer: string;
        postcodeOfInsurer: string;
        cityOfInsurer: string;
        insuranceCustomerNumber: string;
        damageNumber: string;
        phonenumberOfInsurer: string;
        emailOfInsurer: string;
        originalRegDocument: string;
        agreeToSend: boolean;
        registrationFile: File[];
        files: {
            invoice?: File[];
            flightReport: File[];
            expertReport: File[];
            transferProposal?: File[];
            paymentDetails: File[];
        };
    };
};

export type DeathForm = {
    dateOfDeath: string;
    nameOfNotary: string;
    addressOfNotary: string;
    phonenumberOfNotary: string;
    emailOfNotary: string;
    civilityOfBeneficiary: string;
    firstNameOfBeneficiary: string;
    nameOfBeneficiary: string;
    addressOfBeneficiary: string;
    phonenumberOfBeneficiary: string;
    emailOfBeneficiary: string;
    files: {
        deathCertificate?: File[];
        deedOfInheritance?: File[];
    };
};

export type LossForm = {
    email: string;
    phonenumber: string;
    natureOfClaim: string;
    startDate: string;
};

export type RequestContractTransferForm = {
    email: string;
    phonenumber: string;
    buyerNames: string;
    buyerPhonenumber: string;
    buyerEmail: string;
    requestReason: string;
    requestReasonDescription: string;
    employmentStatus: string;
    socioprofessionalCategory: string;
    files: {
        signedConfirmation: File[];
        buyerIdentity: File[];
        bankDetails: File[];
        payslips: File[];
        taxNotice: File[];
        proofOfAddress?: File[];
        buyerProofOfAddress?: File[];
        accomodationCertificate?: File[];
        indentityDoc?: File[];
        registrationDoc: File[];
    };
};
export type DocumentsForm = {
    email: string;
    phonenumber: string;
    documentType: string;
    invoiceMonth: string;
    invoiceYear: string;
    desiredYear: string;
    desiredTaxStatus: string;
    periodStartDate: string;
    periodEndDate: string;
    exitDate: string;
    returnDate: string;
    countryOfDestination: string;
    reason: string;
    comment: string;
};

export type AuthorizationLeaveCountryForm = {
    email: string;
    phonenumber: string;
    exitDate: string;
    returnDate: string;
    reason: string;
    countryOfDestination: string;
};

export type RegistrationCertificateDuplicateForm = {
    email: string;
    phonenumber: string;
    postalAddress: string;
    requestReason: RegistrationCertificateDuplicateReason;
    files: {
        id: File[];
        proofOfAddress: File[];
        registrationCertificate: File[];
        lossDeclaration?: File[];
        theftDeclaration?: File[];
    };
};

export type TenantChangeRequestForm = {
    email: string;
    phonenumber: string;
    transferDate: string;
    reason: string;
    transferTo: string;
    files: {
        dialogueSheet: File[];
        idCopy: File[];
        taxPackage: File[];
        kbisOrInsee: File[];
        newTenantIban: File[];
        vehicleId: File[];
        idCopyManager: File[];
        taxReturn: File[];
    };
    comment: string;
};

export type ChangeDurationMileageForm = {
    email: string;
    phonenumber: string;
    totalDuration: string;
    desiredMileage: string;
    desiredTiresSummer?: string;
    desiredTiresWinter?: string;
    comment?: string;
};

export type OtherForm = {
    email: string;
    phonenumber: string;
    subjectOfClaim: string;
    message: string;
    files: {
        attachment: File[];
    };
};

const authorizationDebitInitialValues: AuthorizationDebitForm = {
    email: '',
    phonenumber: '',
    nameSurname: '',
    amount: '',
    balanceReason: '',
    otherBalanceReason: '',
    expirationMonth: 'Janvier',
};

const deadlineInitialValues: DeadlineForm = {
    email: '',
    phonenumber: '',
    deadline: [],
};

const vehicleClaimInitialValues: VehicleClaimForm = {
    reparable: '',
    nonReparableFields: {
        email: '',
        phonenumber: '',
        productTypeEN: undefined,
        natureOfDamage: '',
        dateOfDamage: '',
        nameOfInsurer: '',
        circumstancesOfTheDisaster: '',
        causeOfSinistre: [],
        addressOfInsurer: '',
        postcodeOfInsurer: '',
        cityOfInsurer: '',
        insuranceCustomerNumber: '',
        damageNumber: '',
        phonenumberOfInsurer: '',
        emailOfInsurer: '',
        originalRegDocument: '',
        agreeToSend: false,
        registrationFile: [],
        files: {
            invoice: [],
            flightReport: [],
            expertReport: [],
            paymentDetails: [],
            transferProposal: [],
        },
    },
};

const deathInitialValues: DeathForm = {
    dateOfDeath: '',
    nameOfNotary: '',
    addressOfNotary: '',
    phonenumberOfNotary: '',
    emailOfNotary: '',
    civilityOfBeneficiary: '',
    firstNameOfBeneficiary: '',
    nameOfBeneficiary: '',
    addressOfBeneficiary: '',
    phonenumberOfBeneficiary: '',
    emailOfBeneficiary: '',
    files: {
        deathCertificate: [],
        deedOfInheritance: [],
    },
};

const lossInitialValues: LossForm = {
    email: '',
    phonenumber: '',
    natureOfClaim: '',
    startDate: '',
};

const requestContractTransferInitialValues: RequestContractTransferForm = {
    email: '',
    phonenumber: '',
    buyerNames: '',
    buyerPhonenumber: '',
    buyerEmail: '',
    requestReason: '',
    requestReasonDescription: '',
    employmentStatus: '',
    socioprofessionalCategory: '',
    files: {
        signedConfirmation: [],
        buyerIdentity: [],
        bankDetails: [],
        payslips: [],
        taxNotice: [],
        proofOfAddress: [],
        buyerProofOfAddress: [],
        accomodationCertificate: [],
        indentityDoc: [],
        registrationDoc: [],
    },
};
const documentsInitialValues: DocumentsForm = {
    email: '',
    phonenumber: '',
    documentType: '',
    invoiceMonth: '',
    invoiceYear: '',
    desiredYear: new Date().getFullYear().toString(),
    desiredTaxStatus: '',
    periodStartDate: '',
    periodEndDate: '',
    exitDate: '',
    returnDate: '',
    countryOfDestination: '',
    reason: '',
    comment: '',
};

const authorizationLeaveCountryInitialValues: AuthorizationLeaveCountryForm = {
    email: '',
    phonenumber: '',
    exitDate: '',
    returnDate: '',
    reason: '',
    countryOfDestination: '',
};

const registrationCertificateDuplicateInitialValues: RegistrationCertificateDuplicateForm = {
    email: '',
    phonenumber: '',
    postalAddress: '',
    requestReason: 'Vol',
    files: {
        id: [],
        proofOfAddress: [],
        registrationCertificate: [],
        lossDeclaration: [],
        theftDeclaration: [],
    },
};

const tenantChangeRequestInitialValues: TenantChangeRequestForm = {
    email: '',
    phonenumber: '',
    transferDate: '',
    reason: '',
    transferTo: '',
    files: {
        dialogueSheet: [],
        idCopy: [],
        taxPackage: [],
        kbisOrInsee: [],
        newTenantIban: [],
        vehicleId: [],
        idCopyManager: [],
        taxReturn: [],
    },
    comment: '',
};

const changeDurationMileageInitialValues: ChangeDurationMileageForm = {
    email: '',
    phonenumber: '',
    totalDuration: '',
    desiredMileage: '',
    desiredTiresSummer: '',
    desiredTiresWinter: '',
    comment: '',
};

const otherInitialValues: OtherForm = {
    email: '',
    phonenumber: '',
    subjectOfClaim: '',
    message: '',
    files: {
        attachment: [],
    },
};

export type RequestInitialValues = {
    contractsSelection: Contract[];
    categorySelection: RequestCategory | '';
    authorizationDebit: AuthorizationDebitForm;
    deadline: DeadlineForm;
    vehicleClaim: VehicleClaimForm;
    death: DeathForm;
    loss: LossForm;
    requestContractTransfer: RequestContractTransferForm;
    documents: DocumentsForm;
    authorizationLeaveCountry: AuthorizationLeaveCountryForm;
    registrationCertificateDuplicate: RegistrationCertificateDuplicateForm;
    tenantChangeRequest: TenantChangeRequestForm;
    changeDurationMileage: ChangeDurationMileageForm;
    other: OtherForm;
};

const getCategorySelectionInitialValue = (isForContact?: boolean): RequestCategory | '' => {
    return isForContact ? 'OTHER' : '';
};

function prefillWithContactDetails<T>(initialValues: T, { email, mobilePhone }: ContactDetails = { email: '' }) {
    return {
        ...initialValues,
        email: email ?? '',
        phonenumber: mobilePhone ?? '',
    };
}

function prefillWithNameAndContactDetails<T>(
    initialValues: T,
    { email, mobilePhone }: ContactDetails = { email: '' },
    identification: Identification = { data: { name: '' }, customerType: 'Retail' },
) {
    return {
        ...initialValues,
        email: email ?? '',
        phonenumber: mobilePhone ?? '',
        nameSurname: `${identification?.data.name || ''} ${identification?.data.surname || ''}`.trim(),
    };
}

export const getInitialValues: (
    contactDetails?: ContactDetails,
    isForContact?: boolean,
    identification?: Identification,
) => RequestInitialValues = (contactDetails, isForContact, identification) => ({
    contractsSelection: [],
    categorySelection: getCategorySelectionInitialValue(isForContact),
    authorizationDebit: prefillWithNameAndContactDetails(
        authorizationDebitInitialValues,
        contactDetails,
        identification,
    ),
    deadline: prefillWithContactDetails(deadlineInitialValues, contactDetails),
    vehicleClaim: {
        ...vehicleClaimInitialValues,
        nonReparableFields: {
            ...prefillWithContactDetails(vehicleClaimInitialValues.nonReparableFields, contactDetails),
        },
    },
    death: deathInitialValues,
    loss: prefillWithContactDetails(lossInitialValues, contactDetails),
    requestContractTransfer: prefillWithContactDetails(requestContractTransferInitialValues, contactDetails),
    documents: prefillWithContactDetails(documentsInitialValues, contactDetails),
    authorizationLeaveCountry: prefillWithContactDetails(authorizationLeaveCountryInitialValues, contactDetails),
    registrationCertificateDuplicate: prefillWithContactDetails(
        registrationCertificateDuplicateInitialValues,
        contactDetails,
    ),
    tenantChangeRequest: prefillWithContactDetails(tenantChangeRequestInitialValues, contactDetails),
    changeDurationMileage: prefillWithContactDetails(changeDurationMileageInitialValues, contactDetails),
    other: prefillWithContactDetails(otherInitialValues, contactDetails),
});
