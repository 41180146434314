import React from 'react';
import { MyProfile, useMyProfile } from '../../components/my-profile';
import { Button, ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { confirmInformationPath } from 'components/navigation/paths';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');
    const history = useHistory();
    const { data } = useMyProfile();
    const title = t('headline');
    const goToConfirmInformationPage = () => {
        history.push(confirmInformationPath());
    };

    return (
        <ContentSection pageWrapSize="medium">
            {data?.identification?.data.isEligibleSiron && (
                <Layout right>
                    <Layout.Item default="1/1" className="u-text-right">
                        <Button
                            icon="document-lines-checkmark"
                            className="u-mb"
                            secondary
                            onClick={goToConfirmInformationPage}
                        >
                            {t('update.details')}
                        </Button>
                    </Layout.Item>
                </Layout>
            )}
            <Heading level={1}>{title}</Heading>

            <MyProfile />
        </ContentSection>
    );
};
