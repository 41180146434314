import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataOverview, DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Identification, RetailCustomerData, SmeCustomerData } from '@cp-fr/common';
import { EditStatus } from '../edit-view/EditView';
import { NameChangeNotification } from '../name-change-notification/NameChangeNotification';
import { ModificationStatus } from 'components/my-profile/modification-status';

export type ConsultViewProps = {
    identification?: Identification;
    startEditing?: () => void;
    editStatus: EditStatus;
    isNameChangePending: boolean;
    isContactInformationChangePending: boolean;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    identification,
    startEditing,
    isNameChangePending,
    isContactInformationChangePending,
    editStatus,
}) => {
    const { t } = useTranslation('my-profile');
    const { t: tP } = useTranslation();
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (editStatus !== 'SUCCESS' && editStatus !== 'NOT_PERFORMED') setPending(false);
    }, [editStatus, isNameChangePending]);

    const getIdentificationList = (identification?: Identification): DefinitionListItem[] => {
        if (!identification) return [];
        const noValuePlaceholder = tP('no-value-placeholder');
        let prefix;
        switch (identification.customerType) {
            case 'Retail': {
                prefix = 'identification.items.retail-customer-data';
                const { name, surname, customerNumber } = identification.data as RetailCustomerData;
                return [
                    {
                        label: t(`${prefix}.name`),
                        value: `${name ?? ''} ${surname ?? ''}`,
                        testId: 'identification-details-name',
                    },
                    {
                        label: t(`${prefix}.customer-number`),
                        value: customerNumber ?? noValuePlaceholder,
                        testId: 'identification-details-customer-number',
                    },
                ];
            }
            case 'BIC':
            case 'BNC':
            case 'BA':
                prefix = 'identification.items.sme-customer-data';
                const { name, surname, customerNumber, sirenNumber } = identification.data as SmeCustomerData;
                return [
                    {
                        label: t(`${prefix}.name`),
                        value: `${name ?? ''} ${surname ?? ''}`,
                        testId: 'identification-details-name',
                    },
                    {
                        label: t(`${prefix}.customer-number`),
                        value: customerNumber ?? '-',
                        testId: 'identification-details-customer-number',
                    },
                    {
                        label: t(`${prefix}.siren-number`),
                        value: sirenNumber ?? '-',
                        testId: 'identification-details-siren-number',
                    },
                ];
            case 'PM': {
                prefix = 'identification.items.sme-customer-data';
                const { companyName, customerNumber, sirenNumber } = identification.data as SmeCustomerData;
                return [
                    {
                        label: t(`${prefix}.company-name`),
                        value: `${companyName ?? ''}`,
                        testId: 'identification-details-company-name',
                    },
                    {
                        label: t(`${prefix}.customer-number`),
                        value: customerNumber ?? '-',
                        testId: 'identification-details-customer-number',
                    },
                    {
                        label: t(`${prefix}.siren-number`),
                        value: sirenNumber ?? '-',
                        testId: 'identification-details-siren-number',
                    },
                ];
            }
            default:
                return [];
        }
    };

    return (
        <DataOverview
            title={t('identification.title')}
            buttonLabel={t('identification.edit-button')}
            buttonProps={{
                onClick: () => {
                    startEditing && startEditing();
                    setPending(true);
                },
                testId: 'editButton',
                disabled:
                    isNameChangePending || isContactInformationChangePending || editStatus === 'SUCCESS' || pending,
            }}
        >
            {(isNameChangePending || editStatus === 'SUCCESS') && <ModificationStatus />}
            <NameChangeNotification lastEditStatus={editStatus} />
            <DefinitionList split={true} list={getIdentificationList(identification)} />
        </DataOverview>
    );
};
