import { RequestDeadlineValidatorErrors } from '../../types';

export const RequestDeadlineError: RequestDeadlineValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    deadline: {
        required: 'deadline is required',
        min: 'deadline is required',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
};
